<template>
    <v-card class="mt-10" tile flat>
            <v-card-title >
               <h3 class="zamu-heading admin-title admin-section-title"> Countries</h3>
                <v-spacer></v-spacer>
            
            </v-card-title>
            <v-card-text>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="admin-search"
                outlined
                dense
            ></v-text-field>
            </v-card-text>
              <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
            <div v-if="countries === null">
                <h3>No countries found</h3>

            </div>
            <v-data-table
                :headers="headers"
                :items="countriesData"
                :search="search"

                class=" users-table"
                v-else
              >
              <template v-slot:item.title="{ item }" width="20%">
               <span class="main-item"> {{ item.name }}</span>
              </template>
             <template v-slot:item.status="{ item }">
              <span class="" :class="item.status === 1 ? 'active' : 'deactivated'"> {{ item.status === 1 ? 'active' : 'deactivated' }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat class="text-right mt-14" width="100%">
                  <v-spacer></v-spacer>
                  <AddCountry  />
                
                  <v-dialog v-model="dialogDelete" max-width="550px">
                    <v-card>
                      <v-card-title class="">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <v-pagination v-if="countries !== null" v-model="currentPage"  class="zamu-pagination my-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>
      </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Countries',
     components: {
        AddCountry: () => import('./newCountry'),
    },
    mixins: [coreMixin],

    data() {
        return {
            countries: null,
            search: '',
            dialog: false,
            dialogDelete: false,
            currentPage: 1,
            deletedID: null,
             notification: '',
            actionClass: '',
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Country Code', value: 'country_code' },
          { text: 'Status', value: 'status' },
          { text: 'Date Created ', value: 'created_at' },
          { text: 'Date Updated', value: 'updated_at' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        editedIndex: -1,
        };
    },
     computed: {
         ...mapGetters(['getUpdateStatus']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
        countriesData () {
            return this.countries !== null ? this.countries.data : null;
        },
        pagination () {
            let data = null;
            if (this.countries !== null) {
                 data = {
                    total: this.countries.total,
                    perPage: this.countries.perPage,
                    page: this.countries.page,
                    lastPage: this.countries.lastPage,
                };
            }
            return data;

        },
    },
    watch: {
        getUpdateStatus(status) {
            if(status) {
                this.retrieveCountries();
            }
        },
        async currentPage() {
              await this.retrieveCountries();

        },
    },
    mounted() {
        this.retrieveCountries();
    },
    methods: {
    ...mapMutations(['setUpdateStatus']),

        async retrieveCountries() {

            this.loading = true;
            this.requested = true;

            const payload = {
              page: this.currentPage,
            };

            const endpoint = `countries`;

            const fullPayload = {
                app: this.apiUrl,
                params: payload,
                endpoint,
            };

            try {
                const response = await this.axiosGetRequest(fullPayload);
                const countries = response.status === 200 ? response.data : null;
                this.countries = countries;
                this.loading = false;
                this.setUpdateStatus(false);
                return countries;
            } catch (error) {
                this.notification = 'Failed to fetch countries data. Kindly try again or contact tech support';
                this.loading = false;
            }
        },

        editItem (item) {
            return this.$router.push({ name: 'country', params: { id: item.id } });
         
        },

      deleteItem (item) {
        this.deletedID = item.id;
        this.dialogDelete = true
      },

     async deleteItemConfirm () {

              const payload = {
                    status: 0,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: `country/deactivate/${this.deletedID}`,
                };
                  try {
                    const res = await this.axiosPutRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.data ? 'Country deactivated successful' : 'Country failed to deactivate';
                    this.actionClass = res.data  ? 'success' : 'error';
                    if(res.data) {
                      this.retrieveMembers()

                    }
                  

                } catch (error) {
                    this.notification = 'Country failed to deactivate. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    }

}
</script>

<style>

</style>